import React from "react"

import {Container} from 'reactstrap'

import { Button, Form, FormGroup, Label, Input } from 'reactstrap';

import Layout from '../components/layout'
import Cloud from '../components/cloud'

export default ({ data }) => (
    <Layout title="Kontakt">
        <Cloud title="KONTAKTIEREN SIE UNS"/>

        <Container className="contact">

            <p className="contact-info text-center">
                Addrese: Bächaustrasse 61 CH-8806 Bäch<br/>
                Email: business@cloudlift.ch<br/>
            </p>

            <hr className="contact-divider"/>

            <h2>Um Ihre Anfrage bestmöglich beantworten zu können, bitten wir Sie, die folgenden Felder vollständig auszufüllen.</h2>

            <Form className="contact-form" method="POST" action="https://formspree.io/business@cloudlift.ch">
                <FormGroup>
                    <Label for="name">Ihr Name*</Label>
                    <Input type="text" name="name" id="name" placeholder="" required/>
                </FormGroup>
                <FormGroup>
                    <Label for="email">E-Mail Adresse*</Label>
                    <Input type="email" name="email" id="email" placeholder="" required/>
                </FormGroup>
                <FormGroup>
                    <Label for="firm">Firma</Label>
                    <Input type="text" name="firm" id="firm" placeholder="" />
                </FormGroup>
                <FormGroup>
                    <Label for="subject">Betreff*</Label>
                    <Input type="text" name="subject" id="subject" placeholder="" required />
                </FormGroup>
                <FormGroup>
                    <Label for="firm">Ihre Nachricht</Label><br/>
                    <textarea style={{width:'100%'}} name="message" id="message" rows="10" required></textarea>
                </FormGroup>
                <Button color="primary" style={{width:'100%'}}>Submit</Button>
            </Form>
        </Container>
    </Layout>
)
